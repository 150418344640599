.Home_Box {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.top_boxes {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.Box {
    width: 200px;
    height: 130px;
    background-color: aliceblue;
    box-shadow: 0px 10px 15px 3px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    color: var(--main-color);
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 15px;
    justify-content: center;
}

.Box p:nth-child(2) {
    font-weight: 500;
    font-size: 17px;
}

.Box p:nth-child(3) {
    font-weight: 800;
    font-size: 18px;
}

.home_i_contain {
    padding: 15px;
    border-radius: 5px;
    background-color: aliceblue;
    width: 100%;
}