.m_container {
    width: 100%;
    height: 100%;
}

.Pagination * {
    color: var(--main-color);
}


.Pagination {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.header {
    text-align: left;
    font-weight: 900;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--main-color);
}

.Headers {
    width: 100%;
    height: fit-content;
    display: flex;
    font-weight: 600;
    gap: 10px;
}

.Headers p {
    width: 14.3%;
    text-align: center;
}

.Headers p:nth-child(2) {
    width: 18%;
}

.i_container {
    width: 100%;
    height: 95%;
    gap: 10px;
    display: flex;
    flex-direction: column;
}


.i_row {
    width: 100%;
    gap: 10px;
    height: fit-content;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: rgb(238, 238, 247);
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
}

.i_row * {
    width: 14%;
    text-align: center;
}

.i_row p:nth-child(2) {
    width: 20%;
    line-break: anywhere;
}

.s_button {
    width: 100px;
    text-align: center;
    background-color: var(--main-color);
    color: white;
}

.s_button:hover {
    background-color: var(--main-color);
}

.Notifications {
    background-color: red;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    margin-top: -5px;
    margin-left: -8px;
}

.Notifications_Chat {
    display: flex;
}

.Chat {
    width: 28vw;
    height: 65vh;
    background-color: rgba(184, 158, 255, 0.555);
    position: absolute;
    z-index: 100;
    right: 15px;
    bottom: 10px;
    border-radius: 5px;
    overflow: scroll;
}

.Chat_container {
    height: 80%;
    width: 100%;
}

#input_container {
    display: flex;
    gap: 5px;
}

.close {
    font-size: 20px;
    margin-top: -10px;
    margin-right: -10px;
}

/* 
* {
    outline: 5px solid yellow;
} */
.s_two {
    height: 10%;
    color: white;
}

.dispatch-btn {
    background-color: var(--main-color);
}