.delegate-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 15px;
    flex-direction: column;
    border-bottom: 2px solid #000066;
    margin-bottom: 3px;
}

.delegate-row div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.delegate-row div p {
    width: 30%;
}

.delegate-row .delegate-btn {
    background-color: var(--main-color);
    color: white;
}

.delegate-center {
    background-color: white;
}