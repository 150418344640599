.details {
    width: 28vw;
    height: 65vh;
    background-color: rgba(184, 158, 255, 0.555);
    position: absolute;
    z-index: 100;
    right: 15px;
    bottom: 10px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.details p {
    padding-left: 50px;
}

p.label_custom {
    padding-left: 25px;
    font-weight: 600;
}

.details select {
    margin-left: 50px;
    width: 60%;
}