.artisans {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-buttons {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
}