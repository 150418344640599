:root {
    --color: #000066;
}


/* Login styling begins */
#logincontainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.mid-container {
    width: 35%;
    height: 50%;
    border-radius: 5px;
    box-shadow: 0px;
    box-shadow: 20px 20px 60px #d9d9d9,
        -20px -20px 60px #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

#logincontainer h1 {
    font-weight: 800;
    color: var(--color);
}

.form-contain {
    width: 80%;
}

.login-button {
    width: 100%;
    background-color: var(--color);
}

.login-button:hover {
    background-color: var(--color);
}

/* Login styling ends */