.Error {
    width: 300px;
    padding: 15px;
    height: fit-content;
    background-color: rgb(255, 0, 0);
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    border-radius: 5px;
}