/* Dashboard styling begins */
#main_container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 0.15fr 0.95fr;
    grid-template-rows: 0.1fr 1fr;
    column-gap: 5px;
    align-items: center;
}

.topnav {
    grid-row: 1/2;
    grid-column: 1/3;
    background-color: var(--main-color);
    color: white;
    width: 100%;
    height: 100%;
}

.middle_container {
    grid-row: 2/3;
    grid-column: 2/3;
    width: 99.8%;
    height: 98%;
    padding: 15px;
    border-radius: 5px;
}

/* Dashboard styling ends */