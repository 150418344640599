#sidebar_container {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 0px 0px 5px 1px rgba(13, 1, 63, 0.205);
    grid-column: 1/2;
    grid-row: 2/3;
}

.side_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    color: var(--main-color);
}

.side_item:nth-child(1) {
    margin-top: 30px;
}

.side_item * {
    color: var(--main-color);
}

p {
    margin: 0px;
}

.side_item:hover::before {
    width: 5px;
    height: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--main-color);
    content: "";
    position: absolute;
    left: 0px;
}

.top_bar {
    display: flex;
    align-items: center;
    color: white;
    font-family: poppins black;
    padding: 15px;
    gap: 15px;
}

.logo_img {
    height: 37px;
}

.Link_con {
    display: contents;
}

.Link_con p {
    margin: 0px;
}