.message {
    width: 75%;
    height: fit-content;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    margin-left: 5px;
    align-self: flex-start;
    background-color: #373741;
}

.message p,
.message2 p {
    line-break: anywhere;
    color: white;
}

.message2 {
    width: 75%;
    height: fit-content;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    margin-right: 5px;
    align-self: flex-end;
    background-color: #000066;
}

.Chat_container {
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 100%;
    overflow-x: scroll;
}

.msg_img {
    width: 100%;
}