.split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
    gap: 13px;
    padding: 13px;
}

.split_containers {
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(192, 192, 247, 0.418);
    padding: 20px;
}

.header {
    text-align: left;
    font-size: 30px;
    color: rgb(0, 0, 102);
    font-weight: 800;
}

.header2 {
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    padding-left: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: rgb(0, 0, 102);
}

.option {
    width: 50%;
}

.Bottom {
    position: relative;
    bottom: -200px;
}

.Bottom1 {
    width: fit-content;
    margin-right: 15px;
}

.Bottom_more {
    bottom: -320px;
}

.height {
    height: 20vh;
}

.btn-update {
    width: 150px;
    padding: 10px;
}